import React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormLabel from '@mui/material/FormLabel';
import FormControlLabel from '@mui/material/FormControlLabel';

export default function MyRadioGroup(props){
	// const [name, setName] = React.useState(props.name);
	// const [value, setValue] = React.useState(props.value);

	function handleChange(e){
        // setValue(e.target.value);
        props.setDataValue(props.name, e.target.value);
    }


	const optionComponents = props.options.map((option, index)=> {
		return <FormControlLabel 
			disabled = {props.disabled}
			key = {`${props.name}--${index}`}
			value={option.value} 
			control={<Radio />} 
			label={option.displayName} 
		/>
	})

	// console.log(`new value: ${props.value}`);
	return ( 
			<RadioGroup name={props.name} onChange = {handleChange} value={props.value || props.options[0].value}>
			<FormLabel id="demo-radio-buttons-group-label">{props.displayName}</FormLabel>
				{optionComponents}
			</RadioGroup>)
}