import React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
// import Container from '@mui/material/Container';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

export default function Header(props) {
	let texts = [];
	if(props !== null && props !== undefined){	
		texts = props.values.map((value, index)=> {
			return (<Typography key = {index} variant="h6" sx={{marginRight:"5px", textShadow: "0px 0px 5px black"}}>{value}</Typography>)
		})
	}

	return (
	<Box sx={{marginBottom:"30px"}}>
	  <AppBar position="static">
	    <Toolbar sx={{ bgcolor: props.color}}>
	    {texts}
	    </Toolbar>
	  </AppBar>
	</Box>
	);
}