import React from 'react';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';

export default function MyTextField(props){
    // const [value, setValue] = React.useState(props.value);

    function handleChange(e){
        const name = e.target.name;
        let newValue = e.target.value;
        if(props.upperCase){
            newValue = newValue.toUpperCase();
        }
        if(props.maxLength){
            if(newValue.length > props.maxLength)
                return 0;
        }
        // setValue(newValue);
        props.setDataValue(name, newValue);
    }

	return <FormControl>
        <TextField
            disabled = {props.disabled}
            margin="normal"
            autoComplete="off"
            required
            fullWidth
            id={props.name}
            key={props.name}
            label={props.displayName}
            name={props.name}
            onChange = {handleChange}
            value = {props.value || ""}
        />
    </FormControl>
}