import React, { useState, useEffect } from 	"react"	;
import {socket} from 	"./socket"	;

import defaultGameData from "./components/defaultGameData"

import GameForm from "./components/Form"
import Header from "./components/Header"
import Alert from 	"@mui/material/Alert";
import Snackbar from 	"@mui/material/Snackbar";
import { ConnectionState } from "./components/ConnectionState";
import { ConnectionManager } from "./components/ConnectionManager";

const defaultUserData = {
	color:"#292AB8",
	values:[ "Username", 500 ]
}

const emptyUserData = {
	color:"white",
	values:[]
}

const loginData = {
	title:"Join Game",
	// instantSubmit: false,
	inputs:[
		{
			name: "gameID", 
			type: "string",
			displayName:"Game ID",
			upperCase:true,
			maxLength:4
		},
		{
			type: "button",
			name: "submitButton",
			displayName: "Join",
			command:"join"
		}
	]
};

const stickySession = true;
const debugForm = false;
const showMessages = false;


export default function Game(){
	const defaultData = (debugForm) ? defaultGameData : loginData;
	const defaultUser = (debugForm) ? defaultUserData : emptyUserData;

	const [isConnected, setIsConnected] = useState(socket.connected);
	const [stageData, setStageData] = useState(defaultData);
	const [message, setMessage] = useState({type: "warning", message:"All good"});//error|warning|info|success
	const [showMessage, setShowMessage] = useState(false);
	const [userData, setUserData] = useState(defaultUser);

	const storage = (stickySession) ? localStorage : sessionStorage;

	useEffect(() => {
	function onConnect() {
		setIsConnected(true);
	}

	function onDisconnect() {
		setIsConnected(false);
		setStageData(defaultData);
		setUserData(defaultUser);
	}

	function onMessage(message) {
		if(!showMessages)
			return 0;
		setMessage(message);
		setShowMessage(true);
	}

	function onStageData(stageData){
		// const value=JSON.stringify(stageData);
		setStageData({...stageData, disabled: false});
	}

	function onUserData(userData){
		console.log("Userdata", userData);
		setUserData(userData);
	}

	function onSession({ sessionID, userID }) {
		// attach the session ID to the next reconnection attempts
		console.log("Got new session info", { sessionID, userID });
		socket.auth = { sessionID };
		// store it in the storage
		storage.setItem("sessionID", sessionID);
		// save the ID of the user
		socket.userID = userID;
	}

	function clearSession(){
		console.log('Clearing Session')
		storage.removeItem("sessionID");
	}


	socket.on("connect", onConnect);
	socket.on("disconnect", onDisconnect);
	socket.on("stageData", onStageData);
	socket.on("userData", onUserData);
	socket.on("message", onMessage);
	socket.on("session", onSession);
	socket.on("clearSession", clearSession);


	// trying to reconnect to the room
	const sessionID = storage.getItem("sessionID");
	if (sessionID) {
		console.log(`Trying to auto reconnect ${sessionID}`);
		setStageData({title:"Reconnecting...", stage:	"", inputs:[]});//todo: set loading screen
		socket.auth = { sessionID:sessionID, isReconnect:true };
		socket.connect();
	}


	return () => {
		socket.off("connect", onConnect);
		socket.off("disconnect", onDisconnect);
		socket.off("stageData", onStageData);
		socket.off("userData", onUserData);
		socket.off("message", onMessage);
		socket.off("session", onSession);
		socket.off("clearSession", clearSession);
	};
}, []);

	function hideMessage(){
		setShowMessage(false);
	}

	function sendFormData(formData, command){
		//trim all string values of the form
		Object.keys(formData).forEach(key => {
			const value = formData[key];
			formData[key] = (typeof value === 'string') ? value.trim() : value;
		});

		console.log(`Command: ${command}\ndata:${JSON.stringify(formData)}`);

		//TODO: disable submit button
		// setStageData({title:"Sending...", stage:	"", inputs:[]});//todo: set loading screen
		setStageData({...stageData, disabled: true});
		if(!isConnected){
			socket.io.opts.query={gameID:formData.gameID};
			socket.connect();
		}
		else{
			socket.emit('action', {data:formData, command:command, stage:stageData.stage}, () => {});
		}
	}

	return (
		<div className = 	"game"	>
			{<Header {...userData}/>}
			<Snackbar open={showMessage} anchorOrigin={{ horizontal: "center", vertical: "bottom"}} autoHideDuration={6000} onClose={hideMessage}>
				<Alert onClose={hideMessage} severity={message.type} sx={{ width: "100%"}}>
					{message.message}
				</Alert>
			</Snackbar>
			{/*<ConnectionState isConnected={ isConnected } />*/}
			{<GameForm {...stageData} submit={sendFormData} debug = {debugForm}/>}
		</div>
		)
}