import Card from './Card';
// import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import './CardGroup.css'

// import FormControl from '@mui/material/FormControl';
// import FormLabel from '@mui/material/FormLabel';

function CardSelector(props){
	return (
		<div onClick = {() => {props.onClick(props.value, !props.selected)}}className = {`card-select-container ${props.selected ? 'card-select-container-selected':''}`} >
			<Card {...props} />
		</div>
	);
}

export default function CardGroup(props){

	function cardClicked(cardValue, isCardSelected){
		if(isCardSelected)
			props.setDataValue(props.name, props.value.concat([cardValue]).splice(0, props.maxCards));
		else
			props.setDataValue(props.name, props.value.filter(v => v!==cardValue));
	}

	const cardComponents = props.cards.map((card, index) => {
		return <CardSelector 
				{...card} 
				selected = {props.value.indexOf(card.value) >= 0}
				// selected = {index % 2}
				key={props.name+"-container-"+index}
				onClick = {cardClicked}
			/>
		})
	return (
			<div>
				<h1>{`(${props.value.length}/${props.maxCards})`}</h1>
				<div className='card-group'>
					{cardComponents}
				</div>
			</div>
		)
}