import './Form.css'

import React, { useState, useEffect } from 'react';

import Button from './inputs/Button';
import TextField from "./inputs/TextField"
import RadioGroup from "./inputs/RadioGroup"
import FlipCard from "./inputs/FlipCard"
import CardGroup from "./inputs/CardGroup"
import TextLine from "./inputs/TextLine"
import Image from "./inputs/Image"
import ButtonGroup from "./inputs/ButtonGroup"

function InputGroup(props){
	let inputComponent;
	if(props.type === "string")
		inputComponent = <TextField {...props}/>;
	else if(props.type === "radio")
		inputComponent = <RadioGroup {...props}/>;
	else if(props.type === "flipCard")
		inputComponent = <FlipCard {...props}/>;
	else if(props.type === "cardGroup")
		inputComponent = <CardGroup {...props}/>;
	else if(props.type === "textLine")
		inputComponent = <TextLine {...props}/>;
	else if(props.type === "button")
		inputComponent = <Button {...props}/>;
	else if(props.type === "buttonGroup")
		inputComponent = <ButtonGroup {...props}/>;
	else if(props.type === "image")
		inputComponent = <Image {...props}/>;
	else inputComponent = <h3 key={props.name}>{`Unknown input ${props.type}`}</h3>

	return (<div className= 'inputGroup' key={props.name+"_group"}>
			{/*<h3 key={props.name+"_label"}>{props.displayName}</h3>*/}
			{inputComponent}
		</div>) 
}

export default function GameForm({inputs, title, submit, debug, disabled}) {

	function getInitialValue(input){
		switch(input.type){
			case 'string':
				return "";
			case 'radio':
				return input.options[0].value;
			case 'flipCard':
				return false;
			case 'cardGroup':
				return [];
			case 'textLine':
				return "";
			// case 'buttonGroup':
			// 	return input.value;
			default:
				return null;
		}
	}

	function getInputsData(){
		//проходится по всем инпутам, берет их текущее или дефолтное значение
		//на основе этой даты будет перерендериваться форма 
		const data = {};
		if(inputs){	
			inputs.forEach((input) => {
				// console.log(input);
				if(['textLine', 'button', 'image'].indexOf(input.type) === -1){//TODO: list ignored types
					// console.log(`${input.name}: ${input.value}`);
					data[input.name] = input.value || getInitialValue(input);
				}
			});
		}
		return data;
	}

	//set initial data based on the input type
	const [formData, setFormData] = React.useState(getInputsData);

	useEffect(() => {//if form is rerendered with new inputs, reset formdata to initial values
		setFormData(getInputsData());
	}, [inputs]);

	function handleSubmit(e){
		e.preventDefault();
		// submit({...formData});
		return 0;
	}

	function setDataValue(name, value){
		const newData = {...formData, [name]:value};
		setFormData(newData);
	}

	function setDataValueAndSubmit(name, value){
		const newData = {...formData, [name]:value};
		setFormData(newData);
		submit(newData, value);
	}

	function submitWithCommand(command){
		submit(formData, command);
	}

	const inputComponents = (inputs) ? inputs.map((input, index) => {
		const componentProps = {...input, 
			submit:submitWithCommand, 
			value:formData[input.name], 
			disabled:disabled, 
			key: input.name, 
			setDataValue: ((input.type != 'buttonGroup') ? setDataValue : setDataValueAndSubmit)
		};

		return InputGroup(componentProps);
	}) : [];

	return (
			<form className="form" onSubmit = {handleSubmit}>
				{(debug) ? <p key="debugPar">{JSON.stringify(formData)}</p> : null}
				{(title) ? <h1 key="title">{title}</h1> : null}
				{inputComponents}
			</form>
			)
}