import React from 'react';
import ButtonGroup from '@mui/material/ButtonGroup';
import Button from '@mui/material/Button';

export default function MyButtonGroup(props){
	let tempValue = props.value; 
	const buttons = props.buttons.map((button, index) => {
		return <Button 
		disabled = {props.disabled}
		type="submit"
		onClick = {() => {
			tempValue = button.command;
			props.setDataValue(props.name, button.command);
			// props.submit(button.command || "submit");
		}}
		// value={props.value}
		key = {index}
		size="large" 
		variant="contained">
	{button.displayName || "Button"}
	</Button>
	});


	return (<ButtonGroup variant="contained" orientation = {props.orientation || "horizontal"}>
				{buttons}
		</ButtonGroup>)
}