import './Card.css'

export default function Card({bgColor, textLines}){
	const textLineComponents = textLines.map((t, i) => <h2 className='card-text' key={i}>{t}</h2>)
	return (
		<div style={{backgroundColor: bgColor}} className = {'card'}>
			<div>
				{textLineComponents}
			</div>
		</div>)
}