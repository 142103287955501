import MuiButton from '@mui/material/Button';

export default function Button({name, displayName, submit, command, disabled}){
	return <MuiButton 
		disabled = {disabled}
		type="submit"
		onClick = {() => submit(command)}
		// value={value}
		size="large" 
		variant="contained">
	{displayName || "OK"}
	</MuiButton>
}