import './Image.css'

export default function Image (props){
    const data = props.data;
    let img = data;
    if (data instanceof ArrayBuffer){
        const arrayBufferView = new Uint8Array( data );
        const blob = new Blob( [ arrayBufferView ], { type: "image/jpeg" } );
        const urlCreator = window.URL || window.webkitURL;
        img = urlCreator.createObjectURL( blob );
    }

    // console.log(img);

    return <div className = "imgContainer">
        <img className = "formImg" src={img} alt={props.displayName}/>
    </div>
}