import Card from './Card' 
import './FlipCard.css';

export default function FlipCard (props){

    function flip(){
        props.setDataValue(props.name,  !props.value);
    }

    return <div className = "flipcard-container">
        <div className = {`card-two-sided ${props.value ? 'flip-back-to-front' : 'flip-front-to-back' }`} onClick={flip} >
            <div className='card-side-flipped'>
                <Card key = {`${props.name}-backSide`} {...props.backSide}/>
            </div>
            <div className='card-side'>
                <Card key = {`${props.name}-frontSide`} {...props.frontSide} />
            </div>
        </div>
    </div>
}